import { Injectable } from '@angular/core';
import {JsonRpc} from 'eosjs';

@Injectable({
  providedIn: 'root'
})

export class Eosjs20Service {

    // rpc: JsonRpc;
    endPoint =  'https://hapi.eosrio.io:443';
    endPoint_Jungle =  'https://jungle2.cryptolions.io:443';
    constructor() {

    }

    async getCertificateCpnj(cnpj: string): Promise<any> {
        const tempRpc = new JsonRpc(this.endPoint_Jungle, { fetch });
        return tempRpc.get_table_rows({
            json: true,              // Get the response as json
            code: 'felipetester', // code: 'eospoltokens', // code: 'felipetester',     // Contract that we target
            scope: cnpj,         // Account that owns the data
            table: 'cert',        // Table name
            limit: 10               // Maximum number of rows that we want to get
        });
    }
}
