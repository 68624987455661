import {Component, OnInit, AfterViewInit, TemplateRef} from '@angular/core';
import {Eosjs20Service} from '../../services/eosjs20.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})
export class CertificateComponent implements OnInit, AfterViewInit {

    modalRef: BsModalRef;
    frmCertificate: FormGroup;
    certificate:  any[];
    residuosAll:  any[];
    residuos:  any[];
    years:  any;
    selectedYear: string;
    cnpjCert: string;

    busy: boolean;
    colorSystem = [
            {sym: 'PAP', color: 'blue', name: 'PAPEL/PAPELÃO' },
            {sym: 'PAL', color: 'red', name: 'PLÁSTICO' },
            {sym: 'VID', color: 'green', name: 'VIDRO' },
            {sym: 'MET', color: 'yellow', name: 'METAL' },
            {sym: 'MAD', color: 'black', name: 'MADEIRA' },
            {sym: '--', color: 'orange', name: 'RESÍDUOS PERIGOSOS' },
            {sym: '--', color: 'white', name: 'RESÍDUOS AMBULATORIAIS E DE SERVIÇOS DE SAÚDE' },
            {sym: '--', color: 'purple', name: 'RESÍDUOS RADIOATIVOS' },
            {sym: '--', color: 'brown', name: 'RESÍDUOS ORGÂNICOS' },
            {sym: '--', color: 'gray', name: 'rESÍDUO GERAL NÃO RECICLÁVEL OU MISTURADO, OU CONTAMINADO NÃO PASSÍVEL DE SEPARAÇÃO.' },
            ];
    constructor(
            public eosjs: Eosjs20Service,
            public fb: FormBuilder,
            private modalService: BsModalService
    ) {
        this.frmCertificate = this.fb.group({
            cnpj: [[Validators.required]]
        });
    }

    ngOnInit() {
        console.log('here init');
        this.selectedYear = 'ANO';
        this.busy = false;
        this.years = [];
        this.cnpjCert = '';
        this.certificate = [];
        this.residuos = [];
        this.residuosAll = [];
       }

    // tslint:disable-next-line:use-life-cycle-interface
    ngAfterViewInit() {
        console.log('here after');
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }
    searchCertificate(cnpj) {
        console.log(cnpj);
        if (cnpj !== '' || cnpj !== undefined) {
            this.busy = true;
            this.residuosAll = [];
            this.years = [];
            this.residuos = [];
            this.selectedYear = 'ANO';
            const _cnpj = cnpj.replace( new RegExp(/\.|\/|\-+/g, 'g') , '' );
            this.eosjs.getCertificateCpnj(_cnpj).then(val => {
                this.cnpjCert = _cnpj;
                const yearsArr = [];
                val.rows[0].amount.forEach(result =>  {
                    if (yearsArr.indexOf(result.year) === -1) {
                        yearsArr.push(result.year);
                    }
                    this.residuosAll.push(result);
                });
                this.years = yearsArr.sort(function(a, b) { return b - a; });
                this.showResiduos(this.years[0]);
            }).catch(err => {
                this.residuos = [];
                this.busy = false;
                this.cnpjCert = '';
                console.log(err);
            });
        }
    }

    showResiduos(year?) {
        this.busy = true;
        this.residuos = [];
        this.selectedYear = year === 'ANO' || year === undefined ? this.years[0] : year;
        this.residuosAll.forEach(result => {
            if (result.year === this.selectedYear) {
                this.residuos.push(result);
            }
        });
        this.busy = false;
    }

    setNameResiduo(res): string {
        const sym = res.replace( new RegExp(/\.|[0-9]|\s+/g, 'g') , '' ).substr(-3);
        return this.colorSystem.find(clr => clr.sym === sym)['name'];
    }
    setColorResiduo(res): string {
        const sym = res.replace( new RegExp(/\.|[0-9]|\s+/g, 'g') , '' ).substr(-3);
        return this.colorSystem.find(clr => clr.sym === sym)['color'];
    }
    chooseByYear(res) {

    }
}

