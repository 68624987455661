import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
    MatAutocompleteModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule, MatRadioModule, MatSelectModule,
    MatSliderModule,
    MatTabsModule,
    MatButtonToggleModule, MatProgressBarModule,
    MatDatepickerModule
} from '@angular/material';

import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';

import {HttpClientModule} from '@angular/common/http';
import {
    BsDropdownModule,
    TooltipModule,
    ModalModule,
} from 'ngx-bootstrap';
import {NgxMaskModule} from 'ngx-mask';

import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {CertificateComponent} from './dashboard/certificate/certificate.component';
import {Eosjs20Service} from './services/eosjs20.service';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        DashboardComponent,
        CertificateComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatDialogModule,
        MatInputModule, MatRadioModule, MatSelectModule,
        MatSliderModule,
        MatTabsModule,
        MatButtonToggleModule, MatProgressBarModule,
        MatDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BsDropdownModule.forRoot(),
        TooltipModule,
        ModalModule.forRoot(),
        NgxMaskModule.forRoot()
    ],
    providers: [Eosjs20Service],
    bootstrap: [AppComponent]
})
export class AppModule {
}
